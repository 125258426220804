/* src/components/Sidebar.css */

.sidebar {
  width: 200px;
  height: 100vh;
  background-color: #fff;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar-list {
  list-style-type: none;
  padding: 0;
}

.sidebar-list li {
  margin-bottom: 15px;
}

.sidebar-list a {
  text-decoration: none;
  font-weight: bold;
  display: block;
  padding: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.sidebar-list a:hover {
  background-color: #f6b60d;
  color: #fff;
}
/* Sidebar.css */

.sidebar {
  transition: all 0.3s ease;
}

.sidebar-list li:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.logout-theme {
  cursor: pointer;
  background: none;
  border: none;
  color: inherit;
}


